import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { mq, color } from "../utils/style"
import { Link } from "gatsby"
import AdSense from "react-adsense"
import ProgressNav from "./progressNav"



const TopicHeader = styled.div({
  textAlign: 'center',
  color: '#FFFFFF',
  backgroundColor: color.main,
  [mq('mobile')]: {
    marginBottom: '5px',
    padding: '5px 10px',
  },
  [mq('pc')]: {
    fontSize: '1.7rem',
    padding: '3px 10px',
  }
});

const List = styled.div({
  [mq('mobile')]: {
    fontSize: '1.9rem',
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      marginBottom: '20px',
      listStyle: 'none',
      margin: '5px 0 0 15px',
      paddingTop: '5px',
      paddingBottom: '5px',
      borderBottom: '1px solid #CCCCCC',
    }
  },
  [mq('pc')]: {
    border: '1px solid #CCCCCC',
    marginBottom: '15px',
    'ul': {
      margin: 0,
      padding: 0,
    },
    'li': {
      marginBottom: '20px',
      listStyle: 'none',  
      margin: '5px 0 0 8px',
      // border-bottom: 1px solid #CCCCCC;
      '&:last-child': {
        borderBottom: 'none'
      }
    }
  }
})

const others = css({
  textAlign: 'right',
  paddingRight: '10px',
  fontSize: '1.5rem',
  '&::before': {
    content: '">> "',
  }
});

const colorMap = {
  "1": "gold",
  "2": "silver",
  "3": "#cd7f32",
  "4": "#EF9491",
  "5": "#DAB04A",
  "6": "#8CB87D",
  "7": "#5FA698",
  "8": "#85B5B7",
  "9": "#A0A7C3",
  "10": "#9992B3",
  "11": "#ffb6c1",
  "12": "#bbffcc"  
};

const RankList = styled.li({
    [mq('mobile')]: {
      height: '32px',
    },
    [mq('pc')]: {
      height: '28px',
    },
  },
  props => ({
    '&::before': {
      content: `"${props.rank}位"`,
      background: colorMap[props.rank],
      width: '100px',
      padding: '3px 10px',
      marginRight: '10px',
      borderRadius: '15px',
    }
  })
);


const categoryTitleMap = {
  "rank": "おすすめメーカー/ショップ",
  "cpu": "CPU性能比較",
  "gpu": "GPU性能比較",
  "pcselection": "目的別パソコンの選び方",
  "pcparts": "パーツ別パソコンの選び方",
};

export default function Navigator({ path, page, pages, headings }) {
  const tags = page && page.tags;
  const category = page && page.category;
  // tagが存在する場合はタグの記事一覧を並べる
  let tagPages;
  if (tags) {
    tagPages = pages.filter(page => {
      if (page.path === path) {
        return false;
      }
      if (page.tags) {
        return page.tags.some(tag => tags.includes(tag))
      }
      return false;
    });
  }
  let categoryPages = "";
  if (category) {
    categoryPages = pages.filter(page => page.category === category);
  }
  // Categoryが存在する場合はカテゴリ一覧を並べる

  // Categoryが存在する場合はCategoryを除いた一覧を並べる

  // Categoryが存在しない場合はCategory一覧を並べる
  return (
    <>
      <div className="center" style={{height: '250px', marginBottom: '10px'}}>
        {/* パソコンレスポンシブ右 */}
        <AdSense.Google
          client='ca-pub-7061143403016575'
          slot='3111420636'
          format='auto'
        />
      </div>
      <div id="topic"></div>
      {tagPages && 
      <List>
        <TopicHeader>関連記事</TopicHeader>
        <ul>
          {tagPages.map(page => {
            return (
              <li key={page.path}><Link to={page.path}>{page.ltitle || page.title}</Link></li>
            )
          })}
        </ul>
      </List>}
      {categoryPages &&
        <List>
        <TopicHeader>{categoryTitleMap[category]}</TopicHeader>
        <ul>
          {categoryPages.map(page => {
            return (
              <li key={page.path}><Link to={page.path}>{page.ltitle || page.title}</Link></li>
            )
          })}
        </ul>
      </List>}
      { Object.keys(categoryTitleMap).map(key => {
        if (key === category) {
          return null;
        }
        return (
          <List key={key}>
          <TopicHeader>{categoryTitleMap[key]}</TopicHeader>
            <ul>
              {pages.filter(page => page.category === key && page.main).map(page => {
                return <li key={page.path}><Link to={page.path}>{page.ltitle || page.title}</Link></li>
              })}
              <li css={others}><Link to={`/category/${key}/`}>{categoryTitleMap[key]} 記事一覧</Link></li>
            </ul>
          </List>
        )
      })}
      <List>
        <TopicHeader id="direct">直販店人気ランキング</TopicHeader>
        <ul>
          <RankList rank="1"><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt=""/>マウスコンピューター</a>
          </RankList>
          <RankList rank="2"><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10003522&type=3&subid=0" rel="nofollow noopener" target="_blank">デル</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10003522&type=3&subid=0" />
          </RankList>
          <RankList rank="3"><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0"/>
          </RankList>
          <RankList rank="4"><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000022&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000022&type=3&subid=0" />
          </RankList>
          <RankList rank="5"><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.28&type=3&subid=0" target="_blank" rel="nofollow noopener">HP</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.28&type=3&subid=0" />
          </RankList>
          <RankList rank="6"><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885898437" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885898437" height="1" width="1" border="0" alt=""/>Lenovo</a>
          </RankList>
          <RankList rank="7"><a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001204&type=3&subid=0" target="_blank" rel="nofollow noopener">Dynabook</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001204&type=3&subid=0" />
          </RankList>
          <RankList rank="8"><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2Findex.html%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />NEC</a>
          </RankList>
        </ul>
      </List>
      <List>
        <TopicHeader id="direct">運営情報</TopicHeader>
        <ul>
          <li><Link to="/aboutme/">当サイトについて</Link></li>
        </ul>
      </List>
      <div className="center" style={{height: '250px'}}>
        {/* パソコンレスポンシブ右 */}
        <AdSense.Google
          client='ca-pub-7061143403016575'
          slot='3377505374'
          format='auto'
        />
      </div>

      {headings && headings.length >= 4 && 
        <ProgressNav
          headings={headings}      
        />
      }
    </>
  );
}
