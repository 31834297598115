import React from "react"
import styled from "@emotion/styled"
import { mq } from "../utils/style";

const Wrapper = styled.div({
  [mq('mobile')] : {
    display: 'none',
  },
  cursor: 'pointer',
  width: '50px',
  height: '50px',
  position: 'fixed',
  background: 'rgba(51,51,51,.5)',
  bottom: '0',
  right: '50px',
  '.arrow-top': {
    marginLeft: '13px',
    marginTop: '19px',
    width: 0,
    height: 0,
    borderLeft: '12px solid transparent',
    borderRight: '12px solid transparent',
    borderBottom: '12px solid white',
  }
})

export default class GotoTop extends React.Component {
  onClick = () => {
    this.props.moveToElement("#top");
  }

  render () {
    return (
      <Wrapper onClick={this.onClick}>
        <div className="arrow-top"></div>
      </Wrapper>
    );
  }
}