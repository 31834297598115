import React from "react"
import styled from "@emotion/styled"
import { mq } from "../utils/style";
import { Link } from "gatsby"

const Wrapper = styled.div({
  fontSize: '1rem',
  padding: 0,
  marginBottom: '5px',
  '.ad': {
    fontSize: '1.2rem',
    textAlign: 'right',
    marginBottom: '3px',
    color: '#777',
    [mq('mobile')]: {
      fontSize: '1.1rem',
    }
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    li :{
      display: 'inline-block',
      '&.next-arrow::before': {
        content: '" > "',
        margin: '0px 5px'
      }
    }
  },
  [mq('mobile')]: {
    marginTop: '5px',
  },
});

const categoryMap = {
  "cpu": "CPU比較",
  "gpu": "GPU比較",
  "rank": "おすすめメーカ－",
  "pcselection": "目的別パソコンの選び方",
  "pcparts":"パーツ別パソコンの選び方"
};

export default function BreadCrumb({ page }) {
  const categoryTitle = page.category && categoryMap[page.category];
  return (
    <Wrapper className="mobile-margin">
      <div className="ad">※当サイトはアフィリエイト広告を使用しています。</div>
      <ul itemScope itemType="http://schema.org/BreadcrumbList">
        <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><Link itemProp="item" to="/"><span itemProp="name">パソコン選び方ガイド トップ</span></Link><meta itemProp="position" content="1"/></li>
        {categoryTitle && <li className="next-arrow" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><Link itemProp="item" to={`/category/${page.category}`}><span itemProp="name">{categoryTitle}</span></Link><meta itemProp="position" content="2" /></li>
        }
        <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" className="next-arrow">
          <span itemProp="name">{page.title}</span>
          <meta itemProp="position" content={categoryTitle ? "3" : "2"} />
        </li>
      </ul>
    </Wrapper>
  );
};