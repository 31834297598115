import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";
import { navigate } from '@reach/router';

const TOCWrapper = styled.div({
  background: color.mainThin,
  [mq('mobile')] : {
    display: 'none',
  },
  '.toc-title': {
    background: color.main,
    borderRadius: '4px',
    color: 'white',
    fontSize: '1.7rem',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '5px 0px',
    padding: '2px',
  },
  '.toc-upper-wrapper': {
    overflowY: 'auto',
    height: 'calc(100vh - 30px)',
  },
  position: 'sticky',
  top: '0',
  width: '100%',
  lineHeight: '1.5',
  '.section': {
    marginTop: '8px',
  },
  ol : {
    fontSize: '1.5rem',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },  
  'ol ol': {
    paddingLeft: '15px',
    fontSize: '1.4rem',
  },
  'li .sidelink': {
    cursor: 'pointer',
    display: 'inline-block',
    color: '#444',
    transition: 'all 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000)',
    '&:hover': {
      fontWeight: 'bold',
      color: color.accent,
      textDecoration: 'underline',
    }
  },
  'li.visible>span': {
    color: color.accent,
    fontWeight: 'bold',
    transform: 'translate( 5px )',
  },
  '#toc-wrapper': {
    position: 'relative',
    padding: '15px',
  },
  '.toc-marker': {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: '-1',
    path: {
      transition: 'all 0.3s ease',
      stroke: color.main,
    }
  }
})

const translateToId = (string) => {
  return string.replace(/ |　/g,'-').replace(/[%().+,/|><?&:]/g,'');
};

export default class TOC extends React.Component {
  constructor(props) {
    super(props);
    const headings = props.headings || props.data.allMdx.nodes[0].headings;
    this.headGroups = headings.reduce((acc, { depth, value }) => {
      if (depth === 2) {
        acc.push([value]);
      } 
      if (depth === 3) {
        acc[acc.length -1].push(value);
      }
      return acc;
    }, []);
    // [[h2], [h2,h3,h3], ....];
  }

  componentDidMount = () => {
    const toc = document.querySelector( '#toc' );
    const tocPath = document.querySelector( '.toc-marker path' );
    let tocItems;

    // Factor of screen size that the element must cross
    // before it's considered visible
    const //TOP_MARGIN = 0,
      BOTTOM_MARGIN = 0.1;

    var pathLength;

    var lastPathStart,
      lastPathEnd;


    const drawPath = () => {
      tocItems = [].slice.call( toc.querySelectorAll( 'li' ) ); // liタグを全て取得
  
      // Cache element references and measurements
      tocItems = tocItems.map( function( item ) {
        var anchor = item.querySelector( '.sidelink' ); // liタグの中にあるaタグを取得
        var target = document.getElementById( translateToId(anchor.innerText)); // targetはid指定slice(1)は#を抜いている
  
        return {
          listItem: item,
          anchor: anchor,
          target: target
        };
      } );
  
      // Remove missing targets
      tocItems = tocItems.filter( function( item ) {
        return !!item.target;
      } );
  
      var path = [];
      var pathIndent;
      tocItems.forEach( function( item, i ) {
  
        var x = item.anchor.offsetLeft - 5, //親要素に対する相対位置 - 5
          y = item.anchor.offsetTop, // 親要素からの相対位置
          height = item.anchor.offsetHeight; // paddingとborder含む要素の高さ
          
  
        if( i === 0 ) {
          path.push( 'M', x, y, 'L', x, y + height );
          item.pathStart = 0;
        }
        else {
          // Draw an additional line when there's a change in
          // indent levels
          if( pathIndent !== x ) path.push( 'L', pathIndent, y );
  
          path.push( 'L', x, y );
  
          // Set the current path so that we can measure it
          tocPath.setAttribute( 'd', path.join( ' ' ) );
          item.pathStart = tocPath.getTotalLength() || 0;
  
          path.push( 'L', x, y + height );
        }
  
        pathIndent = x;
  
        tocPath.setAttribute( 'd', path.join( ' ' ) );
        item.pathEnd = tocPath.getTotalLength();
  
      } );
  
      pathLength = tocPath.getTotalLength();
  
      sync();
  
    };
  
    const sync = () => {
      const windowHeight = window.innerHeight; 
      let pathStart = pathLength,
        pathEnd = 0;
      let visibleItems = 0;
  
      tocItems && tocItems.forEach( function( item, index ) {
        const targetBounds = {};
        const _targetBounds = item.target.getBoundingClientRect();
        let nextTargetBounds;
        targetBounds.top = _targetBounds.top;
        if (tocItems[index + 1]) {
          nextTargetBounds = tocItems[index + 1].target.getBoundingClientRect();
          targetBounds.bottom = nextTargetBounds.top;
        } else {
          targetBounds.bottom = 9999;
        }
        
        if( targetBounds.bottom > 10 && targetBounds.top < windowHeight * ( 1 - BOTTOM_MARGIN ) ) {
          pathStart = Math.min( item.pathStart, pathStart );
          pathEnd = Math.max( item.pathEnd, pathEnd );
          visibleItems += 1;
          item.listItem.classList.add( 'visible' );
        }
        else {
          item.listItem.classList.remove( 'visible' );
        }
  
      } );
  
      // Specify the visible path or hide the path altogether
      // if there are no visible items
      if( visibleItems > 0 && pathStart < pathEnd ) {
        // console.log("pathStart" + pathStart);
        // console.log("LastPathStart" + pathStart);
        // console.log("pathEnd" + pathEnd);
        // console.log("lastPathEnd" + lastPathEnd);
        if( pathStart !== lastPathStart || pathEnd !== lastPathEnd ) {
          tocPath.setAttribute( 'stroke-dashoffset', '1' );
          tocPath.setAttribute( 'stroke-dasharray', '1, '+ pathStart +', '+ ( pathEnd - pathStart ) +', ' + pathLength );
          tocPath.setAttribute( 'opacity', 1 );
        }
      }
      else {
        tocPath.setAttribute( 'opacity', 0 );
      }
  
      lastPathStart = pathStart;
      lastPathEnd = pathEnd;
    };
    this.drawPath = drawPath;
    this.sync = sync;
    window.addEventListener( 'resize', drawPath, false );
    window.addEventListener( 'scroll', sync, false );
    // [HACK] element height cannot be got this timing, so delay and wait 
    // layout is ready.
    setTimeout(() => {
      drawPath();
    }, 300);
  }

  componentWillUnmount = () => {
    window.removeEventListener( 'resize', this.drawPath, false );
    window.removeEventListener( 'scroll', this.sync, false );
  }

  onClick = (path) => {
    navigate(`#${path.replace(/ |　/g,'-').replace(/[%().+,/|><?&:]/g,'')}`);
  }

  render () {
    return (
      <TOCWrapper id="toc">
        <div className="toc-upper-wrapper">
          <div id="toc-wrapper">
            <div className="toc-title">この記事の目次</div>
            <ol className="toc-first-level">
              {
                this.headGroups.map((headGroup, i) => {
                  if (headGroup.length === 1) {
                    return <li key={i} onClick={() => this.onClick(headGroup[0])}><span className="sidelink section">{headGroup[0]}</span></li>
                  } else {
                    return (
                      <li key={i}><span className="sidelink section" onClick={() => this.onClick(headGroup[0])}>{headGroup[0]}</span>
                        <ol className="toc-second-level">
                          {headGroup.map((head, k) => {
                            if (k === 0) {
                              return null;
                            } else {
                              return <li key={k} onClick={() => this.onClick(head)}><span className="sidelink">{head}</span></li>
                            }
                          })}
                        </ol>
                      </li>
                    )
                  }
                })
              }
            </ol>
            <svg className="toc-marker" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
              <path strokeWidth="3" fill="transparent" strokeDasharray="0, 0, 0, 1000" strokeLinecap="round" strokeLinejoin="round" transform="translate(-0.5, -0.5)" />
            </svg>
          </div>
        </div>
      </TOCWrapper>
    );
  }
}