import React from "react"
import styled from "@emotion/styled"
import { mq } from "../utils/style"
import homeIcon from "./images/home.svg"
import crownIcon from "./images/crown.svg"
import topIcon from "./images/top.svg"
import topicIcon from "./images/topic.svg"
import { Link } from "gatsby"

const Wrapper = styled.div({
  zIndex: '1',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: '40px',
  a : {
    display: 'block',
    textDecoration: 'none',
  },
  '.stickyfooter-container' : {
    width: '25%',
    float: 'left',
    boxShadow: 'inset 1px 1px 1px 1px #CCCCCC',
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  '.stickyfooter-icon' : {
    height: '20px',
    marginTop: '5px',
  },
  '.stickyfooter-icon-home' : {
    background: `url(${homeIcon}) center center no-repeat`,
  },
  '.stickyfooter-icon-topic' : {
    background: `url(${topicIcon}) center center no-repeat`,
  },
  '.stickyfooter-icon-shop' : {
    background: `url(${crownIcon}) center center no-repeat`,
  },
  '.stickyfooter-icon-top' : {
    background: `url(${topIcon}) center center no-repeat`,
  },
  '.stickyfooter-text' : {
    height: '15px',
    lineHeight: '15px',
    fontSize: '1.1rem',
    textAlign: 'center',
    color: 'black',
  },
  [mq('pc')]: {
    display: 'none',
  }
});

export default class StickyFooter extends React.Component {
  navigateInPage = url => {
    this.props.moveToElement(url);
  }
  render() {
    return (
      <Wrapper>
        <div  className="stickyfooter-container">
          <Link to="/">
            <div className="stickyfooter-icon stickyfooter-icon-home"></div>
            <div className="stickyfooter-text">Home</div>
          </Link>
        </div>
        <div onClick={() => this.navigateInPage('#topic')} className="stickyfooter-container">
          <div className="stickyfooter-icon stickyfooter-icon-topic"></div>
          <div className="stickyfooter-text">Topic</div>
        </div>
        <div onClick={() => this.navigateInPage('#direct')} className="stickyfooter-container">
          <div className="stickyfooter-icon stickyfooter-icon-shop"></div>
          <div className="stickyfooter-text">Ranking</div>
        </div>
        <div onClick={() => this.navigateInPage('#top')}  className="stickyfooter-container">
          <div className="stickyfooter-icon stickyfooter-icon-top"></div>
          <div className="stickyfooter-text">Top</div>
        </div>
      </Wrapper>
    );
  }
};