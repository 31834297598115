import React from "react"
import Helmet from "react-helmet"

export default function SEO({ page }) {
  if (!page) {
    return <></>;
  }
  const image = page.image && `/images/${page.image}`;
  const baseUrl = `https://pcrecommend.com`;
  const url = baseUrl + (page.path === "/" ? "" : page.path);
  return (
    <Helmet>
      {page.title && <title>{page.title}</title>}
      {page.description && <meta name="description" content={page.description}/>}
      <link rel="icon" href={`${baseUrl}/favicon.ico`} type="image/vnd.microsoft.icon"/>
      <link rel="canonical" href={url} />
      <meta property="og:description" content={page.description || ''} />
      <meta property="og:title" content={page.title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image ? `${baseUrl}${image}` : `${baseUrl}/images/pcrecommend_tw_logo.png`} />
      <meta name="twitter:card" content={image ? "summary_large_image" : "summary"} />
      <meta name="twitter:image" content={image ? `${baseUrl}${image}` : `${baseUrl}/images/pcrecommend_tw_logo.png`} />
      {page.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}